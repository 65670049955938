@use 'styles/_normalize.scss';
@use 'styles/_global.scss';
@use 'styles/_typography.scss';
@use 'styles/components/_solar_system.scss';
@use 'styles/components/_system.scss';
@use 'styles/components/_system_planetary.scss';
@use 'styles/components/_system_satellite.scss';
@use 'styles/components/_system_lunar.scss';
@use 'styles/components/_planets.scss';
@use 'styles/components/_planet.scss';
@use 'styles/components/_file_view.scss';
@use 'styles/components/_logotype.scss';
@use 'styles/components/_file.scss';
@use 'styles/components/_background_animation.scss';
@use 'styles/components/_celestial.scss';
@use 'styles/components/_animation.scss';
@use 'styles/components/_headline.scss';

@import url('https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;700&family=Fira+Sans:wght@400;700&family=Playfair+Display:wght@700&display=swap');