@use '../settings';

.celestial {
    position: absolute;
    z-index: 10;
    border-radius: 100%;
    pointer-events: none;

    &#celestial-sun {
        width: 300vh;
        height: 300vh;
        left: calc(-300vh + 15vw);
        top: -100vh;
    
        @media (max-aspect-ratio: 1/1) {
            width: 300vw;
            height: 300vw;
            left: -100vw;
            top: calc(-300vw + 12em);
        }
    }

    &#celestial-planet {
        width: 150vh;
        height: 150vh;
        left: calc(-150vh + 15vw);
        top: -25vh;

        @media (max-aspect-ratio: 1/1) {
            width: 150vw;
            height: 150vw;
            left: -25vw;
            top: calc(-150vw + 8em);
        }
    }

    &#celestial-moon {
        width: 300vw;
        height: 300vw;
        left: -100vw;
        top: 20em;

        @media (max-aspect-ratio: 1/1), (max-height: 720px) {
            width: 500vw;
            height: 500vw;
            left: -200vw;
            top: 10em;
        }
    }
}