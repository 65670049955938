@use 'settings';

html,
body,
#root,
.app {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}


html,
body {
    font-size: settings.$base-size;
    color: settings.$color-dark;
    font-family: settings.$font-family-text;
    background-color: settings.$color-dark;
}

.button-back {
    position: fixed;
    bottom: 2em;
    width: 100%;
    z-index: 200;

    button {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    @media (max-aspect-ratio: 1/1) {
        bottom: 1em;
    }
}

.button,
button,
input[type='submit'] {
    background-color: settings.$color-dark;
    border: 0.2em solid settings.$color-active;
    color: settings.$color-light;
    font-family: settings.$font-family-headline;
    font-weight: settings.$font-weight-bold;
    font-size: 1.5em;
    padding: 0.25em 1em 0.35em 1em;
    line-height: 1;
    cursor: pointer;
    z-index: 150;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    border-radius: 2em;

    .text {
        position: relative;
        z-index: 2;
    }

    &:after {
        content: '';
        background-color: settings.$color-active;
        left: calc(50% - 10em);
        top: calc(50% - 10em);
        position: absolute;
        width: 20em;
        height: 20em;
        border-radius: 100%;
        transition: all 0.5s ease-in-out;
        transform: scale(0);
        z-index: 1;
    }

    &:hover {
        background-color: settings.$color-active;

        &:after {
            transform: scale(1);
        }
    }
}