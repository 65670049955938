@use '../settings';

.planets {
    z-index: 100;
    display: flex;
    width: 75vw;
    height: 100vh;
    position: absolute;
    flex-wrap: nowrap;
    justify-content: space-around;
    overflow: hidden;
    margin: 0;
    padding: 0 0 0 25vw;
    flex-direction: row;
    transition: opacity ease-in 0.25s;
    top: 0;
    left: 0;

    @media (max-aspect-ratio: 1/1) {
        flex-direction: column;
        width: 100vw;
        height: calc(100vh - 12em - 10em);
        padding: 0;
        top: 15em;
    }
}