@use '../settings';

.file_wrapper {
    width: 10rem;
    margin: 0 1rem 1rem 0;

    .file {
        height: 100%;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        text-align: center;

        &-icon {
            width: 100%;
            min-height: 10rem;
            background-color: settings.$color-secondary;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0 0 1em 0;
        }

        h3 {
            text-transform: lowercase;
            padding: 0.5em;
            margin: 0;

            .highlight {
                background: linear-gradient(180deg, rgba(settings.$color-light, 0) 60%, settings.$color-light 50%);
            }
        }

        &-extension {
            font-weight: normal;
            vertical-align: baseline;
            font-size: 0.75em;
        }
    }
}