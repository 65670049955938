@use '../settings';

#systems .system-lunar {
    .desktop-lunar & {
        opacity: 1;
        pointer-events: inherit;
        z-index: 1;
    }

    .container {
        max-width: 50em;
        padding: 0 2em;
        margin: 0 auto;
    }

    .file-container {
        margin-top: 3em;
        overflow-x: hidden;
        overflow-y: auto;
        height: calc(100% - 20em - 10em);
        z-index: 1;
        position: relative;

        &::-webkit-scrollbar {
            width: 1em;
        }
        
        &::-webkit-scrollbar-track {
            background-color: settings.$color-active;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: settings.$color-secondary;
        }

        @media (max-aspect-ratio: 1/1), (max-height: 720px) {
            height: calc(100% - 10em - 10em);
        }
    }
}