@use '../settings';

.headline-container {
    height: 20em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;

    @media (max-aspect-ratio: 1/1), (max-height: 720px) {
        height: 10em;
    }

    @media (max-aspect-ratio: 1/1) {
        padding-left: 0;
    }

    h1 {
        color: settings.$color-light;
        text-align: center;
        margin: 0;
        padding: 0;
        padding: 0 1em;

        .highlight {
            background: linear-gradient(180deg, rgba(settings.$color-active, 0) 60%, settings.$color-active 50%);
            border-radius: 0.15em;
            box-decoration-break: clone;
        }
    }

    em {
        font-style: normal;
        color: settings.$color-secondary;
    }
}