@use '../settings';

.planet {
    display: block;
    text-align: center;
    align-self: center;
    cursor: pointer;
    width: 100%;
    max-width: 15vw;
    padding: 5em 0;

    @media (max-aspect-ratio: 1/1) {
        width: inherit;
        max-width: inherit;
        height: 100%;
        max-height: 10em;
        max-width: 10em;
        padding: 0;
    }

    &-inner {
        text-align: center;
        position: relative;
        pointer-events: none;
    }

    &-planet {
        height: 10em;
        width: 10em;
        display: inline-block;

        @media (max-aspect-ratio: 1/1),
        (max-height: 720px) {
            height: 5em;
            width: 5em;
        }

        .system-satellites & {
            height: 5em;
            width: 5em;
        }
    }

    &-title {
        text-align: center;
        display: block;
        position: absolute;
        text-align: center;
        top: calc(100% + 2em);
        transform: translate(0, -50%);
        font-size: 1.5em;
        display: block;
        color: settings.$color-light;
        margin: 0;
        font-family: settings.$font-family-headline;
        font-weight: settings.$font-weight-bold;
        width: 100%;
        left: 0;

        @media (max-aspect-ratio: 1/1) {
            bottom: 50%;
            transform: translate(0, 50%);
            top: auto;
        }

        &-inner {
            display: inline;
            left: 0;
            top: 0;
            transition: all 0.25s ease-in-out;
            position: relative;
            line-height: 1.1;
            border-radius: 0.15em;
            box-decoration-break: clone;
            background: linear-gradient(180deg, rgba(settings.$color-black, 0) 60%, settings.$color-black 50%);
            background-repeat: repeat-x;
            background-position: 0 0;

            @media (max-aspect-ratio: 1/1) {
                background: none;
                background-color: settings.$color-black;
            }
        }
    }

    &:hover {
        .planet-title-inner {
            background-position: 0 1em;
            background-color: settings.$color-active;
        }
    }
}