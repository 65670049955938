@use '../settings';

.logotype {
    width: 75vw;
    position: absolute;
    z-index: 50;
    left: -12em;
    top: calc(50vh - 4em);
    display: none;

    &-inner {
        color: settings.$color-dark;
        transform: rotate(-90deg);
        position: absolute;
        font-size: 1em;

        h1 {
            margin: 0;
            padding: 0;
            font-size: 3em;
            font-family: "Syne Mono", monospace;

            span {
                color: settings.$color-active;
            }
        }

        @media (max-aspect-ratio: 1/1) {
            transform: rotate(0deg);
            text-align:center;
            width: 100%;
            font-size: 10vw;
        }
    }

    @media (max-aspect-ratio: 1/1) {
        top: 1.5vh;
        left: 0;
        width: 100%;
    }
}