@use '../settings';

#solar_system {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    #systems {
        z-index: 40;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow:hidden;
    }
}