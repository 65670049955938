// colors
$color-light: #F2E2C4;
$color-dark: #261D11;
$color-active: #A6290D;
$color-secondary: #F2B705;
$color-black: #120e08;

// fonts
$base-size: 14px;
$font-family-text: 'Fira Sans', sans-serif;
$font-family-headline: 'Playfair Display', serif;
$font-family-monospace: 'Fira Mono', monospace;

$font-weight-normal: 400;
$font-weight-bold: 700;