@use '../settings';

.system {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    z-index: 0;
    transition: opacity 0.25s ease-in-out;
}