@use '../settings';

.file_view {
    &-inner {
        padding: 0.5em 0;

        *:first-child {
            margin-top: 0;
        }

        ul {
            margin-left: -1.5em;
        }

        hr {
            border-top: 1px solid settings.$color-dark;
            margin: 1.5em 0 0.5em 0;
        }

        blockquote {
            //border-left: 3px solid settings.$color-secondary;
            margin: 1em 0;
            padding: 0 2.5em;
            position: relative;

            &:before,
            &:after {
                position: absolute;
                font-size: 3em;
                font-family: settings.$font-family-headline;
                line-height: 1;
            }

            &:before {
                content: '„';
                top: -0.75em;
                left: 0;
            }

            &:after {
                content: '“';
                bottom: -0.5em;
                right: 0;
            }
        }

        img {
            width: 100%;
        }

        h1,
        h2,
        h3,
        h4 {
            box-decoration-break: clone;

            .highlight {
                background: linear-gradient(180deg, rgba(settings.$color-secondary, 0) 60%, settings.$color-secondary 50%);
                border-radius: 0.15em;
                box-decoration-break: clone;
            }
        }

        pre {
            padding: 1em 1.25em 0.8em 1.25em;
            background-color: darken(settings.$color-light, 5%);
            position: relative;
            border-left: 0.25em solid settings.$color-secondary;
            overflow-x: auto;
        }
    }
}